import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { BorderedContainer } from "../components/container"
import walletIcon from "../images/wallet.svg"
import { useMoralis } from "react-moralis"
import MintModal from "../components/minting"
import Button from "../components/elements/buttons"
import Inventory from "../components/inventory"
import { changeNetwork } from "../utils/insist"
import { CARABI } from "../solidity/abis"

export default function Home() {
  const { Moralis, chainId, enableWeb3, account } = useMoralis()
  const [address, setAddress] = useState()
  const [usd, setUsd] = useState()
  const [gameChain, setGameChain] = useState()
  const [inventory, setInventory] = useState([])
  const [showMintModal, setShowMintModal] = useState()
  const [updateSubscription, setUpdateSubscription] = useState()
  const [brands, setBrands] = useState([])
  const [models, setModels] = useState([])
  const [prices, setPrices] = useState([])
  const [isOwner, setIsOwner] = useState()

  const updateInventory = async query => {
    const subscription = await query.subscribe()
    subscription.on("create", initInventory)
    setUpdateSubscription(subscription)
  }

  const initInventory = () => {
    const Car = Moralis.Object.extend("Car")
    const query = new Moralis.Query(Car)
    query.equalTo("owner", account.toLowerCase())
    query.find().then(async result => {
      const inventory = []
      for (let car of result) {
        const model = car.get("model")
        await model.fetch()
        const speed = car.get("speed")
        if (!speed) continue
        const element = {
          image: model.get("image")._url,
          model: model.get("name"),
          brand: model.get("brand").get("name"),
          rarity: car.get("rarity"),
          speed: car.get("speed"),
          level: car.get("level") + 1,
        }
        inventory.push(element)
      }
      setInventory(inventory)
    })
    return query
  }
  const initPrices = () => {
    console.log("init prices")
    const ownerOptions = {
      contractAddress: address,
      functionName: "owner",
      abi: CARABI,
    }
    Moralis.executeFunction(ownerOptions).then(owner =>
      setIsOwner(owner.toLowerCase() === account.toLowerCase())
    )
    const usdOptions = {
      contractAddress: address,
      functionName: "usd",
      abi: CARABI,
    }
    Moralis.executeFunction(usdOptions).then(usd => setUsd(usd))
    const priceOptions = {
      contractAddress: address,
      functionName: "getPrices",
      abi: CARABI,
    }
    Moralis.executeFunction(priceOptions).then(resp => {
      const prices = [0, ...resp.map(({ _hex }) => parseInt(_hex))]
      setPrices(prices)
    })
  }

  useEffect(() => {
    if (!account) return setInventory([])
    updateSubscription?.unsubscribe()
    const query = initInventory()
    updateInventory(query)
    // Get brands
    const getBrands = new Moralis.Query("Brand")
    getBrands.find().then(data => {
      const brands = data.map(({ attributes: { name } }) => name)
      setBrands(brands)
    })
    // Get models
    const getModels = new Moralis.Query("Model")
    getModels.find().then(data => {
      const models = data.map(({ attributes }) => attributes)
      setModels(models)
    })
    const getBlockchainData = new Moralis.Query("_EventSyncStatus")
    getBlockchainData.equalTo("tableName", "MintTable")
    getBlockchainData.find().then(([data]) => {
      setAddress(data.attributes.address)
      setGameChain(data.attributes.chainId)
    })
  }, [account, chainId, gameChain])

  useEffect(() => {
    if (!gameChain || !account) return
    if (chainId !== gameChain)
      return changeNetwork(Moralis.web3.provider, {
        chainId: gameChain,
        chainName: "Polygon Mumbai Testnet",
        nativeCurrency: {
          name: "Matic",
          symbol: "MATIC",
          decimals: 18,
        },
        rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
      })
    if (!address) return
    initPrices()
  }, [gameChain, chainId, account, address])

  return (
    <Layout>
      <BorderedContainer>
        <section className="relative w-full flex flex-col p-4 gap-4 items-center justify-center font-Kanit overflow-hidden">
          <div className="text-xl">Inventory</div>
          <Inventory inventory={inventory} />
          <div className="relative flex flex-col-reverse sm:flex-row w-full items-center justify-between gap-4">
            <div className={`flex flex-row items-center gap-4`}>
              <img src={walletIcon} alt="Wallet icon" className="h-6" />
              {account ? (
                <div>
                  Connected to{" "}
                  <span className="text-primary">{account.slice(0, 8)}</span>
                  <span className="font-bold font-sans">...</span>
                </div>
              ) : (
                "Not connected."
              )}
            </div>
            <Button
              callback={async () => {
                if (!account) {
                  // await authenticate({
                  //   signingMessage: "Log in using Moralis",
                  // })
                  enableWeb3()
                } else {
                  setShowMintModal(true)
                }
              }}
            >
              {account ? "Mint" : "Connect wallet"}
            </Button>
          </div>
        </section>
        {showMintModal && (
          <MintModal
            prices={prices}
            brands={brands}
            models={models}
            account={account}
            Moralis={Moralis}
            isOwner={isOwner}
            carAddress={address}
            usdAddress={usd}
            onClose={() => setShowMintModal(false)}
          />
        )}
      </BorderedContainer>
    </Layout>
  )
}

export const Head = () => {
  console.log("head")
  const title = "CryptoForzaGT NFT Store"
  const description = "Buy your new NFTs for CryptoForzaGT"
  const url = "https://nftstore.cryptoforzagt.org"
  const site = "CryptoForzaGT"
  return (
    <>
      <title>{title}</title>
      <meta charset="utf-8" />
      <meta property="og:site_name" content={site} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta name="theme-color" content="#3a20a9" />
      {/* <meta name="twitter:card" content="summary_large_image" /> */}
      <meta property="og:locale" content="en" />
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      {/* <meta property="og:image" content={domain + preview} />
      <meta name="twitter:image" content={domain + preview} /> */}
      <meta property="og:description" content={description} />
      <meta name="application-name" content={site}></meta>
      {/* <link rel="shortcut icon" href={favicon} /> */}
      <link rel="canonical" href={url} />
    </>
  )
}
