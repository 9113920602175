import React, { useState, useEffect } from "react"
import { CARABI, ERC20ABI } from "../../solidity/abis"
import { BorderedContainer } from "../container"
import Button from "../elements/buttons"
import Card from "../elements/cards"
import { DownArrow } from "../elements/icons"

const MintModal = ({
  Moralis,
  account,
  onClose,
  brands,
  models,
  prices,
  isOwner,
  carAddress,
  usdAddress,
}) => {
  const [hasAllowance, setHasAllowance] = useState(isOwner)
  const [successMessage, setSuccessMessage] = useState()
  const [brand, setBrand] = useState(brands[0])
  const [model, setModel] = useState(
    models.filter(
      ({
        brand: {
          attributes: { name },
        },
      }) => name === brand
    )[0]
  )
  const [level, setLevel] = useState(8)

  const checkAllowance = async () => {
    if (!usdAddress) return
    const options = {
      contractAddress: usdAddress,
      functionName: "allowance",
      abi: ERC20ABI,
      params: {
        owner: account,
        spender: carAddress,
      },
    }
    const message = await Moralis.executeFunction(options)
    return (
      (BigInt(message._hex).toString().slice(0, -19) || BigInt(0)) >=
      BigInt(prices[level])
    )
  }

  const requestAllowance = async () => {
    const options = {
      contractAddress: usdAddress,
      functionName: "approve",
      abi: ERC20ABI,
      params: {
        spender: carAddress,
        amount: "9".repeat(60),
      },
    }
    const resp = await Moralis.executeFunction(options)
    setHasAllowance(true)
  }

  const handleMint = async () => {
    let _hasAllowance = isOwner
    if (!isOwner) {
      _hasAllowance = await checkAllowance()
      setHasAllowance(_hasAllowance)
    }
    if (!_hasAllowance) return await requestAllowance()
    const options = {
      contractAddress: carAddress,
      functionName: "mint",
      abi: CARABI,
      params: {
        _model: model.index,
        _level: level - 1,
      },
    }
    try {
      await Moralis.executeFunction(options)
      setSuccessMessage("Mint successful")
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const check = async () => {
      const _hasAllowance = await checkAllowance()
      setHasAllowance(_hasAllowance)
    }
    check()
  }, [])

  useEffect(() => {
    setBrand(brands[0])
  }, [brands])

  useEffect(() => {
    setModel(
      models.filter(
        ({
          brand: {
            attributes: { name },
          },
        }) => name === brand
      )[0]
    )
  }, [models])

  return (
    <section className="fixed inset-0 overflow-y-scroll w-screen h-screen bg-black/50 backdrop-blur-md z-50 flex justify-center items-start py-8">
      <BorderedContainer>
        <section className="relative w-full flex flex-col p-4 gap-4 items-center justify-center font-Kanit bg-[#111111]">
          <div className="text-xl">Mint a new car</div>
          <img className="w-full max-w-sm" src={model?.image?._url} />
          <form className="flex flex-col w-full gap-4">
            <div className="flex flex-row gap-4 items-center">
              <label for="brand-selection">Brand</label>
              <Card>
                <button
                  type="button"
                  id="brand-selection"
                  className="group w-full flex flex-col gap-2 justify-center outline-none"
                >
                  <div
                    type="button"
                    className="group-focus-within:hidden relative w-full text-start outline-none"
                  >
                    <span>{brand}</span>
                    <span className="absolute right-0 inset-y-0 flex items-center">
                      <DownArrow />
                    </span>
                  </div>
                  <div className="hidden group-focus-within:flex w-full flex-col gap-2 justify-center">
                    {brands.map(brandName => {
                      return (
                        <button
                          type="button"
                          className={`w-full outline-none hover:text-primary focus-visible:text-primary hover:bg-slate-700 focus-visible:bg-slate-700 ${
                            brand === brandName ? "text-primary" : "text-white"
                          }`}
                          onClick={() => setBrand(brandName)}
                        >
                          {brandName}
                        </button>
                      )
                    })}
                  </div>
                </button>
              </Card>
            </div>
            <div className="flex flex-row gap-4 items-center">
              <label for="model-selection">Model</label>
              <Card>
                <button
                  type="button"
                  id="model-selection"
                  className="group w-full flex flex-col gap-2 justify-center outline-none"
                >
                  <div
                    type="button"
                    className="group-focus-within:hidden relative w-full text-start outline-none"
                  >
                    <span>{model?.name}</span>
                    <span className="absolute right-0 inset-y-0 flex items-center">
                      <DownArrow />
                    </span>
                  </div>
                  <div className="hidden group-focus-within:flex w-full flex-col gap-2 justify-center">
                    {models.map(({ name, index, image }) => {
                      return (
                        <button
                          type="button"
                          className={`w-full outline-none hover:text-primary focus-visible:text-primary hover:bg-slate-700 focus-visible:bg-slate-700 ${
                            index == model ? "text-primary" : "text-white"
                          }`}
                          onClick={() =>
                            setModel({
                              name,
                              index,
                              image,
                            })
                          }
                        >
                          {name}
                        </button>
                      )
                    })}
                  </div>
                </button>
              </Card>
            </div>
            <div className="flex flex-row items-center gap-4">
              <label for="steps-range">
                <div className="flex flex-row w-max">Level {level}</div>
              </label>
              <input
                id="steps-range"
                type="range"
                min="1"
                max="8"
                value={level}
                onChange={e => {
                  setLevel(parseInt(e.target.value))
                }}
                step="1"
                class="w-full h-1 hover:h-2 transform-gpu duration-200 outline-none hover:bg-slate-700 focus:bg-slate-700 appearance-none cursor-pointer bg-slate-800"
              />
            </div>
            <div className="w-full flex items-center justify-between py-2">
              <span>
                {successMessage}
                <span className="font-sans italic bold">
                  {successMessage && "!"}
                </span>
              </span>
              <div>
                TOTAL<span className="font-sans italic font-bold">:</span>{" "}
                <span className="text-primary">
                  {isOwner ? 0 : prices[level]} BUSD
                </span>
              </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-4">
              <Button
                bgColor="bg-red-800"
                textColor={"hover:text-red-800 focus:text-red-800"}
                callback={onClose}
              >
                Close
              </Button>
              <Button callback={handleMint}>
                {hasAllowance || isOwner ? "Mint" : "Approve"}
              </Button>
            </div>
          </form>
        </section>
      </BorderedContainer>
    </section>
  )
}

export default MintModal
