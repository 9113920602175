import React from "react"
import PropTypes from "prop-types"
import Header from "./header"

const Layout = ({ children }) => {
  return (
    <>
      <main className="w-full min-h-screen bg-[#111111] text-white flex flex-col selection:text-white selection:bg-primary/99">
        <Header />
        <div className="flex h-full flex-col items-center justify-center">
          <div className="w-full h-full max-w-2xl px-4 py-8">{children}</div>
        </div>
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
